import domtoimage from "dom-to-image"
import { v4 as uuidv4 } from 'uuid';

export function formatCurrency(amount: number) {
    const convertToNaira = new Intl.NumberFormat('en-NG', {
        currency: 'NGN',
        style: 'currency',
    });

    return convertToNaira.format(amount)
}

export const snapshot = async (id: string, name: string) => {
    const image = document.getElementById(id) as HTMLElement;
    const pngUrl = new Image();
  
    pngUrl.src = await domtoimage.toPng(image);
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl.src;
    downloadLink.download = `${name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

export const paymentConfig = (amount: number | undefined, email: string | undefined, phone: string | undefined, account: string | undefined, product: string | undefined) => {
    return {
        public_key: process.env.REACT_APP_PUBLIC_KEY,
        tx_ref: uuidv4().substring(0, 18),
        amount: amount,
        currency: 'NGN',
        payment_options: 'card, banktransfer',
        customer: {
            email,
            phone_number: phone
        },
        subaccounts: [{ id: account }],
        customizations: {
          title: "Sterling Utilities",
          description: `Payment for ${product}`
        }
    }
}

export function convertToInternationalFormat(number: any){
    if(number.startsWith("0")){
        return `+234${number.slice(1)}`
    } 

    return number
}

/**
 * table toggle function
 * @param e - event
 * @param datatable - datatable instance
 * @param headToggle - heads component to toggle to
 * @param tailToggle - tail component to toggle to
 * @returns {boolean}
 */
export const tableToggle = (
    e: any,
    datatable: any,
    headToggle: string,
    tailToggle: string
  ): any => {
    let data = datatable.DataTable().cell(e.target.closest('td')).data();
    let dataIndex = datatable.DataTable().cell(e.target.closest('td')).index();
    let text: any = datatable.DataTable().cell(dataIndex.row, dataIndex.column).node()
      if(text.innerHTML === headToggle){
          text.innerHTML = tailToggle;
          return { set: true, data };
      } else {
          text.innerHTML = headToggle;
          return { set: false, data };
      };
  }