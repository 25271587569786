import styled from 'styled-components';
import { Screen } from '../styles'

const modal = ({ r }: any) => {
  return (
    <Modal>
      <ModalCard>
        <h1 style={{ color: 'orangered', textAlign: 'center'}}>Notice!!!</h1>
        <h4 style={{ paddingTop: '3rem'}}>This service has been disabled.</h4>
        <h4>Kindly contact Sterlingtech Admin for support</h4>
        <Button onClick={() => r.closeModal()}>Close</Button>
      </ModalCard>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);

  ${Screen.pad`
  padding-top: 50px;
  `}
  ${Screen.surfaceDuo`
  padding-top: 10px;
  `}
  ${Screen.iPhone`
  padding-top: 10px;
  `}
  ${Screen.smallPhone`
  padding-top: 10px;
  `}
`;
const ModalCard = styled.div`
  width: 550px;
  height: 350px;
  margin: 10rem auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 2rem;

  ${Screen.surfaceDuo`
  width: 400px;
  height: auto;
  padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.iPhone`
  width: 350px;
  height: auto;
  padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.smallPhone`
  width: 300px;
  height: auto;
  padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.galaxyFold`
  width: 270px;
  height: auto;
  padding-top: 2rem .5rem 1rem;
  `}
`;

const Button = styled.div`
height: 3rem;
width: max-content;
margin-top: 3rem;
margin-bottom: 2rem;
background-color: #002668;
box-shadow: 5px 3px 5px #ccc;
color: #fff!important;
border-radius: 5px;
font-size: 1.2rem;
font-weight: bold;
padding: .7rem 2rem;
cursor: pointer;

${Screen.iPhone`
padding: .7rem 1rem;
font-size: 1rem;
`}
${Screen.smallPhone`
padding: .7rem 1rem;
font-size: 1rem;
`}
`;
export default modal;
