import styled from 'styled-components';
import Loader from './loader'

const modal = ({ r, content, loading, actionType }: any) => {
  return (
    <Modal>
      <ModalCard>
        <p>{content}</p>
        <ButtonWrapper>
        <Button className='close' onClick={() => r.handleModal('close')}>Close</Button>
        {
          loading ? <Button><Loader color={'#002668'} /></Button> : <Button onClick={() => r.handleUpdate(actionType)}>Continue</Button>
        }
        </ButtonWrapper>
      </ModalCard>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  z-index: 2;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
`;
const ModalCard = styled.div`
  width: max-content;
  height: auto;
  margin: 10rem auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 3rem;

  p {
  font-size: 18px
  
  }
`;

const Button = styled.div`
height: auto;
width: max-content;
margin-top: 2rem;
background-color: transparent;
border-radius: 5px;
font-size: 14px;
cursor: pointer;
padding: 10px;
color: #002668;
border: 1px solid #002668;
margin-left: 1rem;
&:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #fff;
  background: #002668;
}


&.close {
border: 1px solid #CD5C08;
color: #CD5C08;
&:hover {
  background-color: #CD5C08;
  color: #fff
}
}
`;

const ButtonWrapper = styled.div`
// width: 60%;
// margin: 0 auto;
display: flex;
justify-content: center;
align-items: center;
`
export default modal;
