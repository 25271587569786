import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Modal, Spinner } from '../helpers';
import { EnergyIcon } from '../../assets/images';
import api from '../../service/api';


const App = () => {

  const [display, setDisplay] = useState(false)
  const [estateList, setEstateList] = useState([])
  const [loading, setLoading] = useState(false)
  const mounted = useRef(false)

    const navigate = useNavigate()

    useEffect(() => {
      mounted.current = true
      const getEstates = async () => {
        try {
          setLoading(true)
          const response = await api.req('/estate/all')
          setEstateList(response?.data?.details)
          setLoading(false)
        } catch (error: any) {}
      }
      getEstates()

      return () => {
        mounted.current = false
      }
    }, [])

    const r = {
        handleRoute(estate: string) {
            localStorage.setItem("estate", estate)
            navigate('/payment')
        },
        openModal(){
          setDisplay(true)
        },
        closeModal(){
          setDisplay(false)
        }
    }

    const icons = estateList?.length > 0 ? estateList?.map((item: any, index: React.Key) => (
        <Card key={index} onClick={item?.isActive ? () => r.handleRoute(item?.name) : () => r.openModal()}>
          <p>{item?.name}</p>
        </Card>
    )) : <Card><p>Unable to retrieve list of Estates</p></Card>
    
  if(loading) return <AppContainer><Spinner /></AppContainer>;
  return (
    <AppContainer>
        <HeaderWrapper>
        <Header>Sterling Utilities</Header>
        <img src={EnergyIcon} alt='energy' />
        </HeaderWrapper>
        <Wrapper>
        {icons}
        </Wrapper>
        {display && <Modal r={r} />}
    </AppContainer>
  )
}

export default App;

/** STYLES */
const AppContainer = styled.div`
min-height: 100vh;
width: 100%;
background-color: #F8F0E5;
padding-top: 100px;
`;

const HeaderWrapper = styled.div`
display: flex;
margin: 0 auto;
width: 80%;
padding: 0 2rem;

img {
    width: 40px;
    height: 40px
}
`
const Header = styled.h4`
text-align: center;
font-size: 24px;
color: #CC5B08;
padding-top: 5px;
white-space: no-wrap;
`
const Wrapper = styled.div`
width: 80%;
margin: 3rem auto;
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
gap: 20px;
`;

const Card = styled.div`
width: max-content;
height: auto;
padding: 1rem;
background-color: #FFEEA3; 
border: 1px solid #CC5B08;
cursor: pointer;
border-radius: 5px;

p {
color: #CC5B08;
font-size: 16px;
font-weight: bold;
}
`;