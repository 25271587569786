import { useState, useEffect, useRef } from 'react'
// import { api } from '../../components/hooks';

import { Alert } from '../../components/helpers';
import { Information, Amount, Confirm } from "../../components/meter"
import api from '../../service/api';

const Mobile = () => {
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [meterFound, setMeterFound] = useState(false)
    const [userData, setUserData] = useState({
        meter: '',
        amount: '',
        phone: '',
        email: '',
        product: ''

    })
    const [meterType, setMeterType] = useState("")

    const mounted = useRef(false)

    const { meter, amount, phone, email, product } = userData
    const estate = localStorage.getItem('estate')

    useEffect(() => {
        mounted.current = true
        const handleMeter = async () => {
            try {
                if(meter.length === 11){
                    setLoading(true)
                    const data = await api.req(`/meter/${meter}/${estate}`)
                    if(data.status === "success"){
                        const response = await api.req(`/meter/vending/${meter}`)
                        if(response.status === "success"){
                            setMeterType(data.data.details.meterType)
                            setMeterFound(true)
                            setLoading(false)
                            return;
                        } else {
                            setShowAlert(true)
                            setMeterFound(false)
                            setAlertMessage(response.message)
                            setTimeout(() => setShowAlert(false), 3000)
                            setLoading(false)
                            return;
                        }
                    } else {
                        setShowAlert(true)
                        setMeterFound(false)
                        setAlertMessage(data.message)
                        setTimeout(() => setShowAlert(false), 3000)
                        setLoading(false)
                        return;
                    }
                }
                setLoading(false)
            } catch (error) {
            }
        }
        handleMeter()

        return () => {
            mounted.current = false
        }
    }, [meter])

    const r = {
        onChange(e: React.ChangeEvent<HTMLInputElement>){
            if(e.target.name === 'meter' || e.target.name === 'phone'){
                if((e.target.name === 'meter' || e.target.name === 'phone') && e.target.value.length > 11) return;
                setUserData({
                    ...userData,
                    [e.target.name]: e.target.value.replace(/[^0-9.]/g, '')
                })
            } else {
                setUserData({
                    ...userData,
                    [e.target.name]: e.target.value.replace(" ", "")
                })
            }
        },
        nextStep(){
            setStep(prevState => prevState + 1)
        },

        prevStep(){
            setStep(prevState => prevState - 1)
        }
    }

    switch(step) {
        case 0:
          return (
            <>
              <Information
                      meter={meter}
                      step={step}
                      phone={phone}
                      email={email}
                      product={product}
                      r={r}
                      loading={loading}
                      meterFound={meterFound} powerType={'Grid'}              />
              { showAlert && <Alert message={alertMessage} />}
            </>
            )
        case 1:
          return (
            <Amount
                  r={r}
                  amount={amount}
                  step={step} powerType={'Grid'}             />
        )
        case 2:
          return (
            <Confirm
                  meter={meter}
                  amount={amount}
                  step={step}
                  phone={phone}
                  email={email}
                  product={product}
                  meterType={meterType}
                  r={r}
                  setUserData={setUserData} powerType={'Grid'}             />
        )
          default:
            return null
    }
}

export default Mobile