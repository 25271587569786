import { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Loader, Alert, Spinner, ActionModal } from '../../components/helpers'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../service/api'

const App = ({ show }: any) => {
    const [estateDetails, setEstateDetails] = useState({
        id: '',
        name: '',
        addedBy: '',
        isActive: ''
    })
    const [loading, setLoading] = useState(false)
    const [actionLoading, setActionLoading] = useState(false)
    const [content, setContent] = useState('')
    const [display, setDisplay] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [actionType, setActionType] = useState('')
    const [alertMsg, setAlertMsg] = useState('')
    const [color, setColor] = useState('')
    const navigate = useNavigate()
    const params = useParams()
    const mounted = useRef(false)
    const { id, name, addedBy, isActive} = estateDetails;
    const token = localStorage.getItem('token')
    
    useEffect(() => {
        mounted.current = true
        const getDetails = async () => {
            try {
                setLoading(true)
                const response = await api.req(`/estate/${params?.id}`, undefined, null, token)
                if(response?.data?.badToken) {
                    localStorage.removeItem("token")
                    localStorage.removeItem("user")
                    navigate("/su/admin/login")
                }
                if(response?.status === 'success'){
                    setEstateDetails(prev => ({
                        ...prev,
                        ...response?.data?.details
                    }))
                } 
                setLoading(false)
            } catch (error: any) {}
        }
        getDetails()

        return () => {
            mounted.current = false
        }
    }, [navigate])

    const r = {
        onChange(e: React.ChangeEvent<HTMLInputElement>){
            setEstateDetails({
                ...estateDetails,
                [e.target.name]: e.target.value
            })
        },

        handleModal(action: string, type?: string) {
            if(type){
                setContent(type === 'account' ? `Are you sure you want to ${isActive ? 'deactivate' : 'activate'} account?` : 'Are you sure you want to update account?')
                setActionType(type === 'account' ? 'account' : 'update')
            }
            action === 'open' ? setShowModal(true) : setShowModal(false)
        },

        async handleUpdate(type: string){
            try {
                const body = type === 'account' ? { isActive: isActive ? false : true } : { name }
                setActionLoading(true)
                const response = await api.req(`/estate/update/${params.id}`, 'PATCH', body, token)
                if(response?.data?.badToken) {
                    localStorage.removeItem("token")
                    localStorage.removeItem("user")
                    navigate("/su/admin/login")
                }
                if(response?.status === 'success'){
                    setDisplay(true)
                    setAlertMsg(response?.data?.message)
                    setColor("#64CCC5")
                    setTimeout(() => setDisplay(false), 3000)
                    setTimeout(() => navigate(0), 4000)
                } else {
                    setDisplay(true)
                    setAlertMsg(response?.data?.message)
                    setTimeout(() => setDisplay(false), 3000)
                }
                setActionLoading(false)
            } catch (error) {
                
            }
        }
    }

  return (
    <AppContainer ml={show ? '20rem' : '10rem'}>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Estate</title>   
      </Helmet>
      {
        loading ? <Spinner /> : <Wrapper>
            <Card>
                <ButtonWrapper>
                    <Button onClick={() => r.handleModal('open', 'account')}>{isActive ? 'Deactivate Account' : 'Activate Account'}</Button>
                </ButtonWrapper>
                <Label>Estate ID</Label>
                <Text>{id}</Text>
                <Label>Name</Label>
                <Text>{name}</Text>
                <Label>Added By</Label>
                <Text>{addedBy}</Text>
                <Label>Status</Label>
                <Text>{isActive ? 'Active' : 'Not Active'}</Text>
            </Card>
            <Card className='update'>
                <Header>Update Account</Header>
                <FormContainer>
                <p>Enter name</p>
                <input
                type="text"
                placeholder="Enter estate name"
                name='name'
                onChange={(e) => r.onChange(e)} 
                value={name}
                />
                <Button onClick={() => r.handleModal('open', 'update')} className='update'>Update</Button> 
                </FormContainer>
            </Card>
        </Wrapper>
      }
      {display && <Alert message={alertMsg} color={color} />}
      {showModal && <ActionModal r={r} content={content} loading={actionLoading} actionType={actionType} />}
    </AppContainer>
  )
}

export default App;

/** STYLES */
const AppContainer = styled.div<any>`
display: flex;
flex-direction: column;
flex-wrap: wrap;
width: 80%;
margin-left: ${({ ml }) => ml};
padding-top: 30px;
transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const Wrapper = styled.div`
margin-left: 1rem;
display: flex;
// justify-content: center;
`;

const Card = styled.div`
width: 500px;
height: auto;
padding: 2rem 1rem;
background-color: white;
margin-top: 100px;
margin-bottom: 1rem;

&.update {
margin-left: 50px;
}
`;

const Label = styled.label`
font-size: 12px;
font-weight: 400;
align-self: center;
color: #989da6;
margin: 0;
`;
const Text = styled.p`
font-size: 14px;
color: #44115C;
margin-top: 10px;
padding-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
margin-bottom: 2rem;
`;

const Button = styled.div`
width: max-content;
height: auto;
padding: 10px;
border: 1px solid #CD5C08;
background-color: #fff;
color: #CD5C08;
border-radius: 5px;
font-size: 14px;
cursor: pointer;

&:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #CD5C08;
    color: #fff
}

&.update {
margin-top: 3rem;
}
`;

const Header = styled.h3`
color: #CD5C08;
font-weight: 500;
text-align: center;
`;

const FormContainer = styled.form`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 50px 50px;
  background-color: white;
  border-radius: 10px;

  p {
    font-size: 13px;
    font-weight: 400;
    color: #989da6;
    margin: 0;
  }

  input {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dbd9d9;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: transparent;
    color: #000;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;