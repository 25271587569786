import moment from "moment";

const columns = {
    titles: [
      "Id",
      "Meter Number",
      "Meter Type",
      "Estate",
      "Added By",
      "Date Added"
    ],
    columns($: any) {
      return [
        { data: 'id' },
        { data: 'meterNumber' },
        { data: 'meterType' },
        { data: 'estate'},
        { data: 'addedBy'},
        { 
            data: 'createdAt',
            render: function (data: string){
                return moment(data).format('DD/MM/YYYY HH:mm a')
            }
        }
      ];
    },
  };


  export default columns;