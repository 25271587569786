import styled from 'styled-components';
import Loader from './loader'

const onboarding = ({ r, loading, name }: any) => {
  return (
    <Modal>
      <ModalCard>
            <FormContainer>
                <input
                type="text"
                placeholder="Enter estate name"
                name='name'
                onChange={(e) => r.onChange(e)} 
                value={name}
                />
            </FormContainer>
            <ButtonWrapper>
                <Button className='close' onClick={() => r.handleModal('close')}>Close</Button>
                {
                loading ? <Button><Loader color={'#002668'} /></Button> : <Button onClick={() => r.handleOnboarding()}>Continue</Button>
                }
            </ButtonWrapper>
      </ModalCard>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  z-index: 2;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
`;
const ModalCard = styled.div`
  width: max-content;
  height: auto;
  margin: 5rem auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;

  p {
  font-size: 18px
  
  }
`;

const Button = styled.div`
height: auto;
width: max-content;
// margin-top: 2rem;
background-color: transparent;
border-radius: 5px;
font-size: 14px;
cursor: pointer;
padding: 10px;
color: #002668;
border: 1px solid #002668;
margin-left: 1rem;
&:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #fff;
  background: #002668;
}


&.close {
border: 1px solid #CD5C08;
color: #CD5C08;
&:hover {
  background-color: #CD5C08;
  color: #fff
}
}
`;

const ButtonWrapper = styled.div`
display: flex;
justify-content: center;
// align-items: center;
 margin-bottom: 3rem;
`;

const FormContainer = styled.form`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 30px;
  background-color: transparent;
  border-radius: 10px;

  p {
    font-size: 13px;
    font-weight: 400;
    color: #989da6;
    margin: 0;
  }

  input {
    // margin-top: 10px;
    // margin-bottom: 10px;
    border: 1px solid #dbd9d9;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: transparent;
    color: #000;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export default onboarding;
