import { css } from 'styled-components';


const tables = {

  datatable: css`

    /*
    * Table styles
    */
  table.dataTable {
    width: 100%;
    // padding: 1px;
    margin: 0 auto;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 0.8em;
    text-align: center;
    /*
      * Header and footer styles
      */
    /*
      * Body styles
      */
  }
  table.dataTable thead th,
  table.dataTable tfoot th {
    font-weight: bold;
    
  }
  table.dataTable thead th,
  table.dataTable thead td {
    padding: 10px 18px;
    border-bottom: 1px solid ${({ theme }) => theme.text};
    border-right: 1px solid ${({ theme }) => theme.text};
    border-top: 1px solid ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.body};
  }
  table.dataTable thead tr th:first-child,
  table.dataTable thead tr td:first-child {
    border-left: 1px solid ${({ theme }) => theme.text};
  }

  table.dataTable thead th:active,
  table.dataTable thead td:active {
    outline: none;
  }
  table.dataTable tfoot th,
  table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    border-top: 1px solid ${({ theme }) => theme.text};
  }
  table.dataTable thead .sorting,
  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc,
  table.dataTable thead .sorting_asc_disabled,
  table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    *cursor: hand;
    background-repeat: no-repeat;
    background-position: center right;
  }
  table.dataTable thead .sorting {
    background-image: url("../images/sort_both.png");
  }
  table.dataTable thead .sorting_asc {
    background-image: url("../images/sort_asc.png");
  }
  table.dataTable thead .sorting_desc {
    background-image: url("../images/sort_desc.png");
  }
  table.dataTable thead .sorting_asc_disabled {
    background-image: url("../images/sort_asc_disabled.png");
  }
  table.dataTable thead .sorting_desc_disabled {
    background-image: url("../images/sort_desc_disabled.png");
  }
  table.dataTable tbody tr {
    /* background-color: ${({ theme }) => theme.body}; */
    background-color: ${({ theme }) => theme.body};
  }

  table.dataTable tbody tr:hover {
    background-color: #F8F0E5;
    cursor: pointer;
  }
  table.dataTable tbody tr.selected {
    background-color: #B0BED9;
  }
  table.dataTable tbody th,
  table.dataTable tbody td {
    padding: 20px 20px;
  }
  table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
    border-top: 1px solid #ddd;
  }
  table.dataTable.row-border tbody tr:first-child th,
  table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th,
  table.dataTable.display tbody tr:first-child td {
    border-top: none;
  }
  table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
    // border-top: 1px solid ${({ theme }) => theme.text};
    // border-right: 1px solid ${({ theme }) => theme.text};
  }
  table.dataTable.cell-border tbody tr th:first-child,
  table.dataTable.cell-border tbody tr td:first-child {
    // border-left: 1px solid ${({ theme }) => theme.text};

  }
  table.dataTable.cell-border tbody tr:first-child th,
  table.dataTable.cell-border tbody tr:first-child td {
    border-top: none;
  }
  table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: ${({ theme }) => theme.body};
  }
  table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected {
    background-color: #acbad4;
  }
  table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
    background-color: #f6f6f6;
  }
  table.dataTable.hover tbody tr:hover.selected, table.dataTable.display tbody tr:hover.selected {
    background-color: #aab7d1;
  }
  table.dataTable.order-column tbody tr > .sorting_1,
  table.dataTable.order-column tbody tr > .sorting_2,
  table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1,
  table.dataTable.display tbody tr > .sorting_2,
  table.dataTable.display tbody tr > .sorting_3 {
    background-color: #fafafa;
  }
  table.dataTable.order-column tbody tr.selected > .sorting_1,
  table.dataTable.order-column tbody tr.selected > .sorting_2,
  table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1,
  table.dataTable.display tbody tr.selected > .sorting_2,
  table.dataTable.display tbody tr.selected > .sorting_3 {
    background-color: #acbad5;
  }
  table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #f1f1f1;
  }
  table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
    background-color: #f3f3f3;
  }
  table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke;
  }
  table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #a6b4cd;
  }
  table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #a8b5cf;
  }
  table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #a9b7d1;
  }
  table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #fafafa;
  }
  table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #fcfcfc;
  }
  table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #fefefe;
  }
  table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #acbad5;
  }
  table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #aebcd6;
  }
  table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #afbdd8;
  }
  table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #eaeaea;
  }
  table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
    background-color: #ececec;
  }
  table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #efefef;
  }
  table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #a2aec7;
  }
  table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #a3b0c9;
  }
  table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #a5b2cb;
  }
  table.dataTable.no-footer {
    border-bottom: 1px solid ${({ theme }) => theme.text};
  }
  table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap;
  }
  table.dataTable.compact thead th,
  table.dataTable.compact thead td {
    padding: 4px 17px;
  }
  table.dataTable.compact tfoot th,
  table.dataTable.compact tfoot td {
    padding: 4px;
  }
  table.dataTable.compact tbody th,
  table.dataTable.compact tbody td {
    padding: 4px;
  }
  table.dataTable th.dt-left,
  table.dataTable td.dt-left {
    text-align: left;
  }
  table.dataTable th.dt-center,
  table.dataTable td.dt-center,
  table.dataTable td.dataTables_empty {
    text-align: center;
  }
  table.dataTable th.dt-right,
  table.dataTable td.dt-right {
    text-align: right;
  }
  table.dataTable th.dt-justify,
  table.dataTable td.dt-justify {
    text-align: justify;
  }
  table.dataTable th.dt-nowrap,
  table.dataTable td.dt-nowrap {
    white-space: nowrap;
  }
  table.dataTable thead th.dt-head-left,
  table.dataTable thead td.dt-head-left,
  table.dataTable tfoot th.dt-head-left,
  table.dataTable tfoot td.dt-head-left {
    text-align: left;
  }
  table.dataTable thead th.dt-head-center,
  table.dataTable thead td.dt-head-center,
  table.dataTable tfoot th.dt-head-center,
  table.dataTable tfoot td.dt-head-center {
    text-align: center;
  }
  table.dataTable thead th.dt-head-right,
  table.dataTable thead td.dt-head-right,
  table.dataTable tfoot th.dt-head-right,
  table.dataTable tfoot td.dt-head-right {
    text-align: right;
  }
  table.dataTable thead th.dt-head-justify,
  table.dataTable thead td.dt-head-justify,
  table.dataTable tfoot th.dt-head-justify,
  table.dataTable tfoot td.dt-head-justify {
    text-align: justify;
  }
  table.dataTable thead th.dt-head-nowrap,
  table.dataTable thead td.dt-head-nowrap,
  table.dataTable tfoot th.dt-head-nowrap,
  table.dataTable tfoot td.dt-head-nowrap {
    white-space: nowrap;
  }
  table.dataTable tbody th.dt-body-left,
  table.dataTable tbody td.dt-body-left {
    text-align: left;
  }
  table.dataTable tbody th.dt-body-center,
  table.dataTable tbody td.dt-body-center {
    text-align: center;
  }
  table.dataTable tbody th.dt-body-right,
  table.dataTable tbody td.dt-body-right {
    text-align: right;
  }
  table.dataTable tbody th.dt-body-justify,
  table.dataTable tbody td.dt-body-justify {
    text-align: justify;
  }
  table.dataTable tbody th.dt-body-nowrap,
  table.dataTable tbody td.dt-body-nowrap {
    white-space: nowrap;
  }
  
  table.dataTable,
  table.dataTable th,
  table.dataTable td {
    box-sizing: content-box;
  }
  
  /*
    * Control feature layout
    */
  .dataTables_wrapper {
    position: relative;
    width: 100%;
    clear: both;
    *zoom: 1;
    zoom: 1;
  }
  .dataTables_wrapper .dataTables_length {
    float: left;
  }
  .dataTables_wrapper .dataTables_length select {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: transparent;
    padding: 4px;
  }
  .dataTables_wrapper .dataTables_filter {
    float: right;
    text-align: right;
  }
  .dataTables_wrapper .dataTables_filter input {
    border: 1px solid #aaa;
    border-radius: 10px;
    padding: 10px;
    background-color: transparent;
    margin-left: 3px;
    margin-bottom: 20px; 
  }
  .dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
    font-size: 12px;
  }
  .dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.3em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    *cursor: hand;
    color: #000;
    font-size: 12px;
    border: 1px solid #000;
    border-radius: 2px;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    /* color: ${({theme }) => theme.buttons}; */
    border: 1px solid transparent;
    color: #fff;
    background-color: #CD5C08;
    box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
    /* background-color: transparent; */
    /* Fallback */
    // background: -webkit-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    // /* Chrome 10+, Saf5.1+, iOS 5+ */
    // background: -moz-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    // /* FF3.6 */
    // background: -ms-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    // /* IE10 */
    // background: -o-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    // /* Opera 11.10+ */
    // background: linear-gradient(to bottom, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(230, 230, 230, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
    // -webkit-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
    // text-decoration: none;
    // outline: none;
    // text-overflow: ellipsis;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #666;
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: ${({theme }) => theme.buttons};
    border: 1px solid #E1EEFF;
    background-color: #E1EEFF;
    border-radius: 2px;
    // background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E1EEFF), color-stop(100%, #111));
    // /* Chrome,Safari4+ */
    // background: -webkit-linear-gradient(top, #E1EEFF 0%, #111 100%);
    // /* Chrome10+,Safari5.1+ */
    // background: -moz-linear-gradient(top, #E1EEFF 0%, #111 100%);
    // /* FF3.6+ */
    // background: -ms-linear-gradient(top, #E1EEFF 0%, #111 100%);
    // /* IE10+ */
    // background: -o-linear-gradient(top, #E1EEFF 0%, #111 100%);
    // /* Opera 11.10+ */
    // background: linear-gradient(to bottom, #E1EEFF 0%, #111 100%);
    // /* W3C */
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    color: ${({theme }) => theme.buttons};
    outline: none;
    border: 1px solid #E1EEFF;
    background-color: #E1EEFF;
    // background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E1EEFF), color-stop(100%, #0c0c0c));
    // /* Chrome,Safari4+ */
    // background: -webkit-linear-gradient(top, #E1EEFF 0%, #0c0c0c 100%);
    // /* Chrome10+,Safari5.1+ */
    // background: -moz-linear-gradient(top, #E1EEFF 0%, #0c0c0c 100%);
    // /* FF3.6+ */
    // background: -ms-linear-gradient(top, #E1EEFF 0%, #0c0c0c 100%);
    // /* IE10+ */
    // background: -o-linear-gradient(top, #E1EEFF 0%, #0c0c0c 100%);
    // /* Opera 11.10+ */
    // background: linear-gradient(to bottom, #E1EEFF 0%, #0c0c0c 100%);
    // /* W3C */
    // box-shadow: inset 0 0 3px #111;
  }
  .dataTables_wrapper .dataTables_paginate .ellipsis {
    padding: 0 1em;
  }
  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  }
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    color: ${({ theme }) => theme.text};
  }
  .dataTables_wrapper .dataTables_scroll {
    clear: both;
    // overflow-x: scroll;
  }
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
    *margin-top: -1px;
    -webkit-overflow-scrolling: touch;
    // overflow-x: scroll;
  }
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
    vertical-align: middle;
    // overflow-x: scroll;
  }
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #111;
  }
  .dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
  .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
    border-bottom: none;
  }
  .dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
  
  @media screen and (max-width: 767px) {
    .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
      float: none;
      text-align: center;

    }
    .dataTables_wrapper .dataTables_paginate {
      margin-top: 0.5em;
    }
  }
  @media screen and (max-width: 640px) {
    .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
      float: none;
      text-align: center;
    }
    .dataTables_wrapper .dataTables_filter {
      margin-top: 0.5em;
    }
  }

  `,

  buttons: css`
    
    @keyframes dtb-spinner {
      100% {
        transform: rotate(360deg);
      }
    }
    @-o-keyframes dtb-spinner {
      100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @-ms-keyframes dtb-spinner {
      100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @-webkit-keyframes dtb-spinner {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @-moz-keyframes dtb-spinner {
      100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    div.dt-button-info {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 400px;
      margin-top: -100px;
      margin-left: -200px;
      background-color: white;
      border: 2px solid #111;
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      text-align: center;
      z-index: 21;
    }
    div.dt-button-info h2 {
      padding: 0.5em;
      margin: 0;
      font-weight: normal;
      border-bottom: 1px solid #ddd;
      background-color: #f3f3f3;
    }
    div.dt-button-info > div {
      padding: 1em;
    }
    
    div.dt-button-collection-title {
      text-align: center;
      padding: 0.3em 0 0.5em;
      font-size: 0.9em;
    }
    
    div.dt-button-collection-title:empty {
      display: none;
    }
    
    button.dt-button,
    div.dt-button,
    a.dt-button,
    input.dt-button {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      margin-right: 0.333em;
      margin-bottom: 0.333em;
      padding: 1em 2em;
      /* font-weight: bold; */
      /* color: ${({ theme }) => theme.buttons}; */
      color: #fff;
      // border: 1px solid ${({theme }) => theme.buttons};
      border: 1px solid transparent;
      /* background-color: ${({ theme }) => theme.body}; */
      background-color: #CD5C08;
      border-radius: 5px;
      cursor: pointer;
      font-size: 0.7em;
      line-height: 1.6em;
      white-space: nowrap;
      overflow: hidden;
      transition: all 0.8s ease;
      &:hover {
        background-color: #CD5C08!important;
        color: #fff!important;
        border: 1px solid transparent!important;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
    }
    button.dt-button.disabled,
    div.dt-button.disabled,
    a.dt-button.disabled,
    input.dt-button.disabled {
      cursor: default;
      /* opacity: 0.4; */
    }
    button.dt-button:active:not(.disabled), button.dt-button.active:not(.disabled),
    div.dt-button:active:not(.disabled),
    div.dt-button.active:not(.disabled),
    a.dt-button:active:not(.disabled),
    a.dt-button.active:not(.disabled),
    input.dt-button:active:not(.disabled),
    input.dt-button.active:not(.disabled) {
      background-color: transparent;
      /* Fallback */
      background: -webkit-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* Chrome 10+, Saf5.1+, iOS 5+ */
      background: -moz-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* FF3.6 */
      background: -ms-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* IE10 */
      background: -o-linear-gradient(top, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* Opera 11.10+ */
      background: linear-gradient(to bottom, rgba(179, 179, 179, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(179, 179, 179, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
      box-shadow: inset 1px 1px 3px #999999;
    }
    button.dt-button:active:not(.disabled):hover:not(.disabled), button.dt-button.active:not(.disabled):hover:not(.disabled),
    div.dt-button:active:not(.disabled):hover:not(.disabled),
    div.dt-button.active:not(.disabled):hover:not(.disabled),
    a.dt-button:active:not(.disabled):hover:not(.disabled),
    a.dt-button.active:not(.disabled):hover:not(.disabled),
    input.dt-button:active:not(.disabled):hover:not(.disabled),
    input.dt-button.active:not(.disabled):hover:not(.disabled) {
      box-shadow: inset 1px 1px 3px #999999;
      background-color: rgba(0, 0, 0, 0.1);
      /* Fallback */
      background: -webkit-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* Chrome 10+, Saf5.1+, iOS 5+ */
      background: -moz-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* FF3.6 */
      background: -ms-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* IE10 */
      background: -o-linear-gradient(top, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* Opera 11.10+ */
      background: linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(128, 128, 128, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
    }
    button.dt-button:hover,
    div.dt-button:hover,
    a.dt-button:hover,
    input.dt-button:hover {
      text-decoration: none;
      border: 1px solid ${({theme }) => theme.buttons};
    }
    button.dt-button:hover:not(.disabled),
    div.dt-button:hover:not(.disabled),
    a.dt-button:hover:not(.disabled),
    input.dt-button:hover:not(.disabled) {
      border: 1px solid #666;
      background-color: transparent;
      /* Fallback */
      background: -webkit-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* Chrome 10+, Saf5.1+, iOS 5+ */
      background: -moz-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* FF3.6 */
      background: -ms-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* IE10 */
      background: -o-linear-gradient(top, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      /* Opera 11.10+ */
      background: linear-gradient(to bottom, rgba(153, 153, 153, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(153, 153, 153, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
    }
    button.dt-button:focus:not(.disabled),
    div.dt-button:focus:not(.disabled),
    a.dt-button:focus:not(.disabled),
    input.dt-button:focus:not(.disabled) {
      border: 1px solid #426c9e;
      text-shadow: 0 1px 0 #c4def1;
      outline: none;
      background-color: #79ace9;
      /* Fallback */
      background: -webkit-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
      /* Chrome 10+, Saf5.1+, iOS 5+ */
      background: -moz-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
      /* FF3.6 */
      background: -ms-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
      /* IE10 */
      background: -o-linear-gradient(top, #d1e2f7 0%, #79ace9 100%);
      /* Opera 11.10+ */
      background: linear-gradient(to bottom, #d1e2f7 0%, #79ace9 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="#d1e2f7", EndColorStr="#79ace9");
    }
    
    .dt-button embed {
      outline: none;
    }
    
    div.dt-buttons {
      position: relative;
      float: left;
    }
    div.dt-buttons.buttons-right {
      float: right;
    }
    
    div.dataTables_layout_cell div.dt-buttons {
      float: none;
    }
    div.dataTables_layout_cell div.dt-buttons.buttons-right {
      float: none;
    }
    
    div.dt-button-collection {
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
      margin-top: 3px;
      padding: 8px 8px 4px 8px;
      border: 1px solid #ccc;
      border: 1px solid rgba(0, 0, 0, 0.4);
      background-color: white;
      overflow: hidden;
      z-index: 2002;
      border-radius: 5px;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
      box-sizing: border-box;
    }
    div.dt-button-collection button.dt-button,
    div.dt-button-collection div.dt-button,
    div.dt-button-collection a.dt-button {
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      display: block;
      float: none;
      margin-bottom: 4px;
      margin-right: 0;
    }
    div.dt-button-collection button.dt-button:active:not(.disabled), div.dt-button-collection button.dt-button.active:not(.disabled),
    div.dt-button-collection div.dt-button:active:not(.disabled),
    div.dt-button-collection div.dt-button.active:not(.disabled),
    div.dt-button-collection a.dt-button:active:not(.disabled),
    div.dt-button-collection a.dt-button.active:not(.disabled) {
      background-color: #dadada;
      /* Fallback */
      background: -webkit-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
      /* Chrome 10+, Saf5.1+, iOS 5+ */
      background: -moz-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
      /* FF3.6 */
      background: -ms-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
      /* IE10 */
      background: -o-linear-gradient(top, #f0f0f0 0%, #dadada 100%);
      /* Opera 11.10+ */
      background: linear-gradient(to bottom, #f0f0f0 0%, #dadada 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="#f0f0f0", EndColorStr="#dadada");
      box-shadow: inset 1px 1px 3px #666;
    }
    div.dt-button-collection.fixed {
      position: fixed;
      top: 50%;
      left: 50%;
      margin-left: -75px;
      border-radius: 0;
    }
    div.dt-button-collection.fixed.two-column {
      margin-left: -200px;
    }
    div.dt-button-collection.fixed.three-column {
      margin-left: -225px;
    }
    div.dt-button-collection.fixed.four-column {
      margin-left: -300px;
    }
    div.dt-button-collection > :last-child {
      display: block !important;
      -webkit-column-gap: 8px;
      -moz-column-gap: 8px;
      -ms-column-gap: 8px;
      -o-column-gap: 8px;
      column-gap: 8px;
    }
    div.dt-button-collection > :last-child > * {
      -webkit-column-break-inside: avoid;
      break-inside: avoid;
    }
    div.dt-button-collection.two-column {
      width: 400px;
    }
    div.dt-button-collection.two-column > :last-child {
      padding-bottom: 1px;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      -ms-column-count: 2;
      -o-column-count: 2;
      column-count: 2;
    }
    div.dt-button-collection.three-column {
      width: 450px;
    }
    div.dt-button-collection.three-column > :last-child {
      padding-bottom: 1px;
      -webkit-column-count: 3;
      -moz-column-count: 3;
      -ms-column-count: 3;
      -o-column-count: 3;
      column-count: 3;
    }
    div.dt-button-collection.four-column {
      width: 600px;
    }
    div.dt-button-collection.four-column > :last-child {
      padding-bottom: 1px;
      -webkit-column-count: 4;
      -moz-column-count: 4;
      -ms-column-count: 4;
      -o-column-count: 4;
      column-count: 4;
    }
    div.dt-button-collection .dt-button {
      border-radius: 0;
    }
    
    div.dt-button-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      /* Fallback */
      background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
      /* IE10 Consumer Preview */
      background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
      /* Firefox */
      background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
      /* Opera */
      background: -webkit-gradient(radial, center center, 0, center center, 497, color-stop(0, rgba(0, 0, 0, 0.3)), color-stop(1, rgba(0, 0, 0, 0.7)));
      /* Webkit (Safari/Chrome 10) */
      background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
      /* Webkit (Chrome 11+) */
      background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
      /* W3C Markup, IE10 Release Preview */
      z-index: 2001;
    }
    
    @media screen and (max-width: 640px) {
      div.dt-buttons {
        float: none !important;
        text-align: center;
      }
    }
    button.dt-button.processing,
    div.dt-button.processing,
    a.dt-button.processing {
      color: rgba(0, 0, 0, 0.2);
    }
    button.dt-button.processing:after,
    div.dt-button.processing:after,
    a.dt-button.processing:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      margin: -8px 0 0 -8px;
      box-sizing: border-box;
      display: block;
      content: " ";
      border: 2px solid #282828;
      border-radius: 50%;
      border-left-color: transparent;
      border-right-color: transparent;
      animation: dtb-spinner 1500ms infinite linear;
      -o-animation: dtb-spinner 1500ms infinite linear;
      -ms-animation: dtb-spinner 1500ms infinite linear;
      -webkit-animation: dtb-spinner 1500ms infinite linear;
      -moz-animation: dtb-spinner 1500ms infinite linear;
    }
  `,
};

export default tables;
