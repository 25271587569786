import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { Helmet } from 'react-helmet'
// import { api } from '../../components/hooks';
import { Alert, Loader } from '../../components/helpers';
import { useNavigate } from 'react-router-dom';
// import { MeterProps } from '../../types';
import decorate from '../../components/styles/decorate';
import { Tables } from '../../components/styles';
import tables from '../../components/styles/table';
import { QueueTable } from '../../components/table'
import { MeterQueueColumns } from '../../components/columns';
import api from '../../service/api';

const AddMeter = ({ show }: any) => {
    const [ userData, setUserData ] = useState({ 
        meterNumber: "",
        meterType: "",
        estate: ""
    })
    const [estatesData, setEstatesData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [color, setColor] = useState("")
    const [meterArr, setMeterArr] = useState<any>([])
    const navigate = useNavigate()
    const { meterNumber, meterType, estate } = userData;
    const mounted = useRef(false)
    const token = localStorage.getItem('token')
    
    useEffect(() => {
      mounted.current = true
      const getEstatesData = async () => {
        try {
          setLoading(true)
          const data = await api.req('/estate/all', undefined, null)
          if(data?.status === "success"){
            setEstatesData(data?.data?.details)
          }
          setLoading(false) 
        } catch (error: any) {}
      }
      getEstatesData()
  
      return () => {
        mounted.current = false
      }
    }, [navigate])

    const estateOptions = estatesData?.length > 0 && estatesData?.map(({ name}) => (
      <option key={name} value={name}>{name}</option>
    ))

    const r = {
        onChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>){
            setUserData({
                ...userData,
                [e.target.name]: e.target.value.replace(" ", "")
            })
        },
        validateMeter(e: React.ChangeEvent<HTMLInputElement>){
            const meterRegex = /^[0-9\b]{1,11}$/;
            if(e.target.value === '' || meterRegex.test(e.target.value)){
                setUserData({
                    ...userData,
                    meterNumber: e.target.value.replace(" ", "")
                })
            }
        },

        async handleMeter(){
            try {
                const data = await api.req("/meter/add", 'POST', meterArr, token)
                return data;
            } catch (error: any) {}
        },

        async onSubmit(e: React.KeyboardEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement>){
            try {
              e.preventDefault()
              setLoading(true)
              const data = await r.handleMeter()
              if(data?.data?.badToken) {
                localStorage.removeItem("token")
                localStorage.removeItem("user")
                navigate("/su/admin/login")
              }
              setLoading(false)
              if(data?.status === "success"){
                setShowAlert(true)
                setAlertMessage(data?.data?.message)
                setColor("#64CCC5")
                setTimeout(() => setShowAlert(false), 3000)
                setMeterArr([])
                setUserData({ meterNumber: "", meterType: "", estate: "" })
              } else {
                setShowAlert(true)
                setAlertMessage(data?.message)
                setTimeout(() => setShowAlert(false), 3000)
              }
            } catch (error) {
            }
          },

          handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
            if (e.key === 'Enter') {
              r.onSubmit(e);
            }
          },

          async populateMeterArr() {
            const data = {
              meterNumber,
              meterType,
              estate
            }
            setMeterArr([...meterArr, data]);
            setUserData({
              meterNumber: '',
              meterType: '',
              estate: ''
            })
        },

    }
  return (
    <AppContainer ml={show ? '20rem' : '10rem'}>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Meter</title>   
      </Helmet>

      <Wrapper>
        <FormContainer 
        // onSubmit={(e) => r.onSubmit(e)}
        >

        <h1>Meter Number</h1>
        <input
            type="text"
            placeholder="Enter Meter Number"
            name='meterNumber'
            onChange={(e) => r.validateMeter(e)} 
            value={meterNumber}
            onKeyDown={(e) => r.handleKeyPress(e)}
        />

          <h1>Meter Type</h1>
          <Select>
            <select
            name='meterType'
            onChange={(e) => r.onChange(e)}
            value={meterType}
            >
              <option></option>
              <option value={'Dual'}>Dual</option>
              <option value={'Single'}>Single</option>
              <option value={'Water'}>Water</option>
            </select>
          </Select>

          <h1>Estate</h1>
          <Select>
            <select
            name='estate'
            onChange={(e) => r.onChange(e)}
            value={estate}
            >
              <option></option>
              {estateOptions}
            </select>
           </Select>

          <Button onClick={() => r.populateMeterArr()} className={meterNumber.length < 11 || !meterType || !estate ? 'disabled' : undefined}>Add to queue</Button>

        </FormContainer>
      </Wrapper>
      <DatatableContainer>
            <TableContainer>
                <QueueTable data={meterArr} columns={MeterQueueColumns} setMeterArr={setMeterArr} />
            </TableContainer>
        </DatatableContainer>

        {
          loading 
          ? <Button><Loader color={ '#fff' } /></Button> 
          : <Button 
          onClick={(e) => r.onSubmit(e)} className={meterArr.length < 1 ? 'disabled' : undefined}>Upload Meters</Button>
        }
      {showAlert && <Alert message={alertMessage} color={color }/>}
    </AppContainer>
  )
}


/** STYLES */
const AppContainer = styled.div<any>`
display: flex;
flex-direction: column;
flex-wrap: wrap;
width: 70%;
margin-left: ${({ ml }) => ml};
padding-top: 30px;
transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
padding-bottom: 3rem;
`;

const Wrapper = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  margin-left: 10px;
  width: 80%;
`;

const FormContainer = styled.form`
  display: flex;
  width: 60%;
  height: auto;
  flex-direction: column;
  padding: 50px 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  margin-bottom: 50px;
  margin-top: 100px;

  h1 {
    font-size: 13px;
    font-weight: 800;
    /* font-family: 'Roboto Flex', sans-serif; */
    align-self: left;
    color:  #CD5C08;
    margin: 0;
    padding: 0px;
    padding-left: 10px;
    margin-bottom: 1px;
    margin-top: 20px;
  }

  p {
    font-size: 11px;
    font-weight: 400;
    align-self: center;
    color: #989da6;
    margin: 0;
  }

  input {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dbd9d9;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: transparent;
    color: #0a3d94;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Button = styled.div`
  width: max-content;
  display: flex;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;

  &.disabled {
    pointer-events: none;
    background-color: #ddd;
    color: #CD5C08;
  }
`;

const Select = styled.div`
${decorate.select};

select {
    width: 110%;
    margin-top: 10px;
    border: 1px solid #f0f0f0;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: transparent;
    color: #44115C;
    cursor: pointer
  }

  label {
    color: #CD5C08;
    font-size: 14px;
  }
`;

const DatatableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 1rem;
  ${Tables.datatable};
  ${tables.buttons};
`;

const TableContainer = styled.div`
  position: relative;
  display: flex;
  margin: 10px 0px 20px 0px;
  flex-direction: column;
  border: 1px solid #ccc;
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);

  border-radius: 10px;
  background-color: #fff;
  padding: 50px;

  &:hover {
    /* box-shadow: ${({ theme }) => theme.hover}; */
  }
`;

export default AddMeter