import { useState, useEffect, useRef } from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Tables } from '../../components/styles'
import tables from '../../components/styles/table'
import { useNavigate } from "react-router-dom"
import { Spinner } from "../../components/helpers"
import api from "../../service/api"
import { BaseTable } from "../../components/table"
import { MeterColumns } from "../../components/columns"

const Meter = ({ show }: any) => {
  localStorage.setItem('currentPath', '/admin/meters')
  const [meterData, setMeterData] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const mounted = useRef(false)
  const [user, setUser] = useState<any>()
  const token = localStorage.getItem("token")
  

  useEffect(() => {
    mounted.current = true
    setUser(JSON.parse(localStorage.getItem("user") as string))
    const getMeterData = async () => {
      try {
        setLoading(true)
        const data = await api.req('/meter/all', undefined, null, token)
        if(data?.data?.badToken) {
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          navigate("/su/admin/login")
        }
        if(data?.status === "success"){
          setMeterData(data?.data?.details?.rows)
        }
        setLoading(false) 
      } catch (error: any) {}
    }
    getMeterData()

    return () => {
      mounted.current = false
    }
  }, [navigate])
  return (
    <AppContainer ml={show ? '20rem' : '10rem'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Meters</title>   
      </Helmet>
      {
        loading ? <Spinner /> : <Wrapper>
        <Title>
          <h2>Meters</h2>
        </Title>
        <ButtonWrapper>
          {user?.role === "admin" && <Button onClick={() => navigate("/admin/meters/new")}>Upload Meters</Button>}
          
        </ButtonWrapper>
        <DatatableContainer>
            <TableContainer>
                <BaseTable data={meterData} columns={MeterColumns} />
            </TableContainer>
        </DatatableContainer>
      </Wrapper>
      }
    </AppContainer>
  )
}


/** STYLES */
const AppContainer = styled.div<any>`
display: flex;
flex-direction: column;
flex-wrap: wrap;
width: 80%;
margin-left: ${({ ml }) => ml};
padding-top: 30px;
transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;
const Wrapper = styled.div`
margin-left: 1rem;
`
const Title = styled.div`
h2 {
    text-transform: uppercase;
    padding-top: 1rem;
    color: #CD5C08;
    font-weight: 600;
    font-size: 20px;
}
`;
const ButtonWrapper = styled.div`
margin-top: 2rem;
`
const Button = styled.div`
width: max-content;
background-color: #CD5C08;
color: #fff;
font-weight: 400;
padding: .7rem;
border-radius: 5px;
font-size: 14px;
cursor: pointer;
`;

const DatatableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 1rem;
  margin-top: 3rem;
  ${Tables.datatable};
  ${tables.buttons};
`;

const TableContainer = styled.div`
  position: relative;
  display: flex;
  margin: 10px 0px 20px 0px;
  flex-direction: column;
  border: 1px solid #ccc;
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);

  border-radius: 10px;
  background-color: #fff;
  padding: 50px;

  &:hover {
    /* box-shadow: ${({ theme }) => theme.hover}; */
  }
`;
export default Meter