import styled from 'styled-components'
import { Helmet } from 'react-helmet'

const Dashboard = ({ show }: any) => {
  localStorage.setItem('currentPath', '/admin/dashboard')
  return (
    <AppContainer>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Dashboard</title>   
        </Helmet>
    </AppContainer>
  )
}

/** STYLES */
const AppContainer = styled.div``;


export default Dashboard