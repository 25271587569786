import { useState, useEffect, useRef } from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tables } from '../../components/styles'
import tables from '../../components/styles/table'
import { BaseTable } from '../../components/table'
// import { api } from "../../components/hooks"
import { useNavigate } from "react-router-dom"
import { TransactionsColumns, UpdateTransactionColumns } from "../../components/columns"
import { Spinner } from "../../components/helpers"
import api from "../../service/api";

const Meter = ({ show }: any) => {
  localStorage.setItem('currentPath', '/admin/transactions')
  const [transactionData, setTransactionData] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState<any>({
    startDate: new Date(moment().subtract(120, 'days') as any),
    endDate: new Date(),
  })
  const [toggle, setToggle] = useState(false)
  const navigate = useNavigate()
  const mounted = useRef(false)
  const { startDate, endDate } = query
  const user = JSON.parse(localStorage.getItem("user") as string)
  const token = localStorage.getItem("token")
  

  useEffect(() => {
    mounted.current = true
    const getTransactionsData = async () => {
      try {
        setLoading(true)
        const data = await api.req(`/transaction?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`, undefined, null, token)
        if(data?.data?.badToken) {
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          navigate("/su/admin/login")
        }

        if(data?.status === "success"){
          setTransactionData(data?.data?.details?.rows)
        }
        setLoading(false)
      } catch (error: any) {}
    }
    getTransactionsData()

    return () => {
      mounted.current = false
    }
  }, [navigate, toggle]);

  return (
    <AppContainer ml={show ? '20rem' : '10rem'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Transactions</title>   
      </Helmet>
      {
        loading ? <Spinner /> : <Wrapper>
        <Title>
          <h2>Transactions</h2>
        </Title>
        <DateSelect>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '55%',
          }}>
          <Picker>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={query.startDate}
              onChange={(date: any) => setQuery({ ...query, startDate: date})}
              selectsStart
              startDate={query.startDate}
              endDate={query.endDate}
            />
          </Picker>
          <Picker>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={query.endDate}
              onChange={(date: any) => setQuery({ ...query, endDate: date})}
              selectsStart
              startDate={query.startDate}
              endDate={query.endDate}
              minDate={query.startDate}
            />
          </Picker>
          </div>
          <Button onClick={() => setToggle(!toggle)}>Fetch Transactions</Button>
        </DateSelect>
        <DatatableContainer>
            <TableContainer>
              <BaseTable data={transactionData} columns={user?.role === "operator" ? UpdateTransactionColumns : TransactionsColumns} />
            </TableContainer>
        </DatatableContainer>
      </Wrapper>
      }
    </AppContainer>
  )
}


/** STYLES */
const AppContainer = styled.div<any>`
display: flex;
flex-direction: column;
flex-wrap: wrap;
width: 80%;
margin-left: ${({ ml }) => ml};
padding-top: 30px;
transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;
const Wrapper = styled.div`
margin-left: 1rem;
`
const Title = styled.div`
margin-bottom: 2rem;
h2 {
    text-transform: uppercase;
    padding-top: 1rem;
    color: #CD5C08;
    font-weight: 600;
    font-size: 20px;
}
`;

const DatatableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 1rem;
  margin-top: 3rem;
  ${Tables.datatable};
  ${tables.buttons};
`;

const TableContainer = styled.div`
  position: relative;
  display: flex;
  margin: 10px 0px 20px 0px;
  flex-direction: column;
  border: 1px solid #ccc;
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);

  border-radius: 10px;
  background-color: #fff;
  padding: 50px;

  &:hover {
    /* box-shadow: ${({ theme }) => theme.hover}; */
  }
`;

const DateSelect = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  float: left;
  width: auto;
  margin: 5px 0px 0px 0px;
  
  p {
    padding: 0;
    margin: 0;
  }
`;

const Picker = styled.div`
  position: relative;
  width: 100px;
  margin: 0;
  margin-left: 10px;
  input {
    margin: 0;
    background-color: #fff;
    border: 1px solid #17274F;
    border-radius: 5px;
    padding: 5px 10px;
    width: 90px;
    outline: none;
    color: #17274F;
    font-size: 13px;
    font-weight: 700;
    font-family: sans-serif;
    transition: all 0.8s ease;
    cursor: pointer;
    &:hover {
      background-color: #E1EEFF;
    }
  }
`;

const Button = styled.div`
  width: max-content;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  background-color: #CD5C08;
  font-size: 13px;
  cursor: pointer;
`
export default Meter