import styled from 'styled-components'
import { DesktopImg } from '../../assets/svg'
import { BsInfoCircle } from "react-icons/bs"

const Desktop = () => {
  return (
    <Box>
        <Container>
            <Alert>
                <BsInfoCircle color='#80B3FF' fontWeight={'bold'} />
                <h4>This application can only be accessed on mobile devices</h4>
            </Alert>
            <ImageWrapper>
                <Image src={DesktopImg} alt='desktop-image' />
            </ImageWrapper>
        </Container>

    </Box>
  )
}

const Box = styled.div`   
width: 100%;
`;
const Container = styled.div`
margin: 5rem 10rem;
`;
const Alert = styled.div`
width: 100%;
display: flex;
padding: 1rem 2rem;
background-color: #E5F6Fd;
border-radius: 4px;

h4 {
    color: #014361;
    padding-left: .5rem;
}
`;

const ImageWrapper = styled.div`
display: flex;
justify-content: center;
`
const Image = styled.img`
    
`
export default Desktop