import { PaymentProps } from '../../types'
import styled from 'styled-components';
import Steppers from './stepper';
import { Loader } from "../helpers"
import { Decorate, Screen } from '../styles';

const Information = ({ meter, product, step, phone, email, r, loading, meterFound }: PaymentProps) => {
  return (
    <Box>
    <Card>
    <Steppers step={step} />
    <FormContainer>
            <Select>
            <label>What are you paying for?</label>
            <select
            name='product'
            onChange={(e) => r.onChange(e)}
            value={product}
            >
              <option></option>
              <option value={'Grid_Power'}>Grid Power</option>
              <option value={'Generator_Power'}>Generator Power</option>
              <option value={'Water'}>Water</option>
            </select>
            </Select>
            {
              product && (
                <input
                type='text'
                placeholder='Enter your meter number'
                name='meter'
                onChange={(e) => r.onChange(e)}
                value={meter}
                />
              )
            }
            {
                meterFound && meter?.length === 11 && (
                    <>
                    <input
                    type='text'
                    placeholder='Enter your phone number'
                    name='phone'
                    onChange={(e) => r.onChange(e)}
                    value={phone}
                    />
                    <input
                    type='email'
                    placeholder='Enter your email address'
                    name='email'
                    onChange={(e) => r.onChange(e)}
                    value={email}
                    />
                    </>
                )
            }
            {
                loading 
                ? <Button><Loader color={'#fff'} /></Button>
                : <Button className={(!meterFound || String(meter)?.length < 11 || String(phone)?.length < 11) ? "disable": undefined} onClick={() => r.nextStep()}>Next</Button>
            }
            
    </FormContainer>
    </Card>
    </Box>
  )
}

const Box = styled.div`
width: 100%;
min-height: 100vh;
background-color: #F8F0E5;
padding: 3rem 0;
`;

const Card = styled.div`
width: 350px;
margin: 5rem auto;
padding: 3rem 2rem;
background-color: #fff;

${Screen.smallPhone`
width: 90%

`}
`;
const FormContainer = styled.form`
margin: 50px auto 10px;

  input{
    width: 100%;
    margin-top: 20px;
    border: 1px solid #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    background-color: transparent;
    color: #44115C;
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
`;

const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 10px 10px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 15px;
  cursor: pointer;
  justify-content: center;

  &.disable {
    pointer-events: none;
    background-color: #ddd;
    color: #CD5C08;
  }
`;

const Select = styled.div`
${Decorate.select};

select {
    width: 110%;
    margin-top: 10px;
    border: 1px solid #f0f0f0;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: transparent;
    color: #44115C;
  }

  label {
    color: #CD5C08;
    font-size: 14px;
  }
`
export default Information;