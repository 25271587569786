const columns = {
    titles: [
      'Meter Number',
      'Meter Type',
      'Estate',
      ''
    ],
    columns($: any) {
      return [
        { data: 'meterNumber' },
        { data: 'meterType' },
        { data: 'estate' },
        {
          data: 'meterNumber',
          createdCell: function (td: HTMLElement) {
            $(td).html(`<button class="btn">Remove</button>`)
          }
        } 
      ];
    },
  };


  export default columns;