import moment from "moment";
import { Tools } from "../../utils";


const columns = {
    titles: [
      'Id',
      'Meter Number',
      'Product',
      'Token',
      'Amount',
      'Units',
      'Phone Number',
      'Email',
      'Trx Reference',
      'Trx Date'
    ],
    columns($: any) {
      return [
        { data: 'id' },
        { data: 'meterNumber' },
        { data: 'product' },
        { data: 'token' },
        { 
            data: 'amount',
            render: function (data: string) {
                return Tools.formatCurrency(parseInt(data))
            }
        
        },
        { data: 'units'},
        { data: 'phone'},
        { data: 'email'},
        { data: 'reference'},
        { 
            data: 'createdAt',
            render: function (data: string){
                return moment(data).format('DD/MM/YYYY HH:mm a')
            }
        }
      ];
    },
  };


  export default columns;