import moment from "moment";

const columns = {
    titles: [
      "Id",
      "Name",
      "Status",
      "Added By",
      "Date Added",
      ""
    ],
    columns($: any) {
      return [
        { data: 'id' },
        { data: 'name' },
        { data: 'isActive',
          render: function(data: boolean){
            let color;
            if(data){
              color= "#4caf50"
            } else {
              color = "#c62828"
            }
            return `<span style="color: ${color}">${data ? "Active" : "Not Active"}</span>`
          }
         },
        { data: 'addedBy'},
        { 
            data: 'createdAt',
            render: function (data: string){
                return moment(data).format('DD/MM/YYYY HH:mm a')
            }
        },
        {
          data: 'id',
          createdCell: function (td: HTMLElement, cellData: string) {
            $(td).html(`
              <div>
                <a class="btn" href="/admin/estate/${cellData}">View</a>
              </div>
              `)
          }
        }
      ];
    },
  };


  export default columns;