import React, { useState } from 'react';
import { PaymentProps } from '../../types';
import styled from 'styled-components';
import Steppers from './stepper';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { useNavigate } from 'react-router-dom';
import { Alert, Loader } from '../helpers';
import { Screen } from '../styles';
import { Tools } from '../../utils';
import api from '../../service/api';

const Confirm = ({ meter, amount, step, phone, email, product, r, meterType, setUserData, powerType: initialPowerType }: PaymentProps) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [powerType, setPowerType] = useState<"Grid" | "Generator" | "">(""); 
  const [showError, setShowError] = useState(false); 
  const navigate = useNavigate();

  const serviceCharge = 0.015 * Number(amount);
  const totalAmount = Number(amount) + serviceCharge;

  const subAccount = process.env.REACT_APP_SUB_ACCOUNT;
  const estate = localStorage.getItem("estate");

  const handleFlutterwave = useFlutterwave(Tools.paymentConfig(totalAmount, email, phone, subAccount, product) as any);

  const handleTransaction = async (reference: string) => {
    try {
      const body = {
        meterNumber: meter,
        product,
        amount,
        email,
        phone,
        reference,
        meterType,
        estate,
        powerType
      };
      setLoading(true);
      localStorage.setItem("details", JSON.stringify(body));
      const data = await api.req('/transaction/add', 'POST', body);
      if (data.status === "success") {
        localStorage.setItem("date", data.data.details.createdAt);
        const payload = {
          meterNumber: meter,
          amount,
          meterType,
          powerType
        };
        const response = await api.req('/transaction/generate_token', 'POST', payload);
        if (response.status === "success") {
          localStorage.setItem("meterToken", response.data.token);
          const updatedTrx = await api.req(`/transaction/${data.data.details.id}`, 'PATCH', { token: response.data.token });
          if (updatedTrx.status === "failed") {
            setShowAlert(true);
            setAlertMessage(updatedTrx.message);
            setTimeout(() => setShowAlert(false), 3000);
            setLoading(false);
            return;
          }
          setLoading(false);
        } else {
          setShowAlert(true);
          setAlertMessage(response.message);
          setTimeout(() => setShowAlert(false), 3000);
          setLoading(false);
        }
      } else {
        setShowAlert(true);
        setAlertMessage(data.message);
        setTimeout(() => setShowAlert(false), 3000);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      // Handle error
    }
  };

  const handlePayment = () => {
    if (!powerType) {
      setShowError(true); 
      return;
    }
    try {
      handleFlutterwave({
        callback: response => {
          closePaymentModal();
          if (response.status === "successful" || response.status === "completed") {
            handleTransaction(response.tx_ref);
            navigate("/receipt");
            setUserData({
              meter: '',
              amount: '',
              phone: '',
              email: '',
              product: '',
              powerType: ''
            });
          } else {
            setShowAlert(true);
            setAlertMessage("Transaction Failed");
            setTimeout(() => setShowAlert(false), 3000);
          }
        },
        onClose: () => {}
      });
    } catch (error) {
      // Handle error
    }
  };

  const handlePowerTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPowerType(e.target.value as "Grid" | "Generator" | "");
    setShowError(false); 
  };

  return (
    <Box>
      <Card>
        <Steppers step={step} />
        <FormContainer>
          <input readOnly value={product} />
          <input readOnly value={meter} />
          <input readOnly value={phone} />
          <input readOnly value={email} />
          <input readOnly value={amount} />
          <select value={powerType} onChange={handlePowerTypeChange}>
            <option value="">Select An Option</option>
            <option value="Grid">Grid</option>
            <option value="Generator">Generator</option>
            <option value="Water">Water</option>
          </select>
          {showError && <p>Please select a type to proceed.</p>} 
          <ButtonWrapper>
            <Button onClick={() => r.prevStep()}>Back</Button>
            {
              loading
                ? <Button> <Loader color={"#fff"} /></Button>
                : <Button onClick={() => handlePayment()} >Pay</Button> 
            }
          </ButtonWrapper>
        </FormContainer>
      </Card>
      {showAlert && <Alert message={alertMessage} />}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #F8F0E5;
  padding: 3rem 0;
`;

const Card = styled.div`
  width: 350px;
  margin: 5rem auto;
  padding: 3rem 2rem;
  background-color: #fff;

  ${Screen.smallPhone`
    width: 90%
  `}
`;

const FormContainer = styled.form`
  margin: 10px auto;

  input, select {
    width: 100%;
    margin-top: 20px;
    border: 1px solid #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    background-color: transparent;
    color: #44115C;
  }

  p {
    margin-top: 1rem;
    color: red; /* Error message color */
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 15px;
  cursor: pointer;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Confirm;
