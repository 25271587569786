import { useState, useEffect, useRef } from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Tables } from '../../components/styles'
import tables from '../../components/styles/table'
import { BaseTable } from '../../components/table'
import { useNavigate } from "react-router-dom"
import { Alert, OnboardingModal, Spinner } from "../../components/helpers"
import api from "../../service/api"
import { EstateColumns } from "../../components/columns"

const Meter = ({ show }: any) => {
  localStorage.setItem('currentPath', '/admin/estates')
  const [estateData, setEstateData] = useState([])
  const [loading, setLoading] = useState(false)
  const [onboardingLoading, setOnboardingLoading] = useState(false)
  const [display, setDisplay] = useState(false)
  const [estateDetails, setEstateDetails] = useState({
    name: ''
  })
  const [showAlert, setShowAlert] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')
  const [color, setColor] = useState('')
  const navigate = useNavigate()
  const mounted = useRef(false)
  const { name } = estateDetails; 
  const token = localStorage.getItem('token')

  useEffect(() => {
    mounted.current = true
    const getEstatesData = async () => {
      try {
        setLoading(true)
        const data = await api.req('/estate/all')
        if(data?.data?.badToken) {
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          navigate("/su/admin/login")
        }
        if(data?.status === "success"){
          setEstateData(data?.data?.details)
        }
        setLoading(false) 
      } catch (error: any) {}
    }
    getEstatesData()

    return () => {
      mounted.current = false
    }
  }, [navigate])

  const r = {
    handleModal(action?: string){
      action === 'open' ? setDisplay(true) : setDisplay(false)
    },

    onChange(e: React.ChangeEvent<HTMLInputElement>){
      setEstateDetails({
        ...estateDetails,
        [e.target.name]: e.target.value
      })
    },

    async handleOnboarding(){
      try {
        setOnboardingLoading(true)
        const response = await api.req('/estate/onboard', 'POST', { name }, token)
        if(response?.data?.badToken) {
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          navigate("/su/admin/login")
        } 

        if(response?.status === "success"){
          setShowAlert(true)
          setAlertMsg(response?.data?.message)
          setColor("#64CCC5")
          setTimeout(() => setShowAlert(false), 3000)
          setTimeout(() => navigate(0), 4000)
        } else {
          setShowAlert(true)
          setAlertMsg(response?.data?.message)
          setTimeout(() => setShowAlert(false), 3000)
        } 
        setOnboardingLoading(false)
      } catch (error: any) {}
    }
  }
  return (
    <AppContainer ml={show ? '20rem' : '10rem'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Estates</title>   
      </Helmet>
      {
        loading ? <Spinner /> : <Wrapper>
        <Title>
          <h2>Estates</h2>
        </Title>
        <ButtonWrapper>
          <Button onClick={() => r.handleModal('open')}>Onboard Estate</Button>
        </ButtonWrapper>
        <DatatableContainer>
            <TableContainer>
                <BaseTable data={estateData} columns={EstateColumns} />
            </TableContainer>
        </DatatableContainer>
      </Wrapper>
      }
      { display && <OnboardingModal r={r} name={name} loading={onboardingLoading} />}
      { showAlert && <Alert color={color} message={alertMsg}  />}
    </AppContainer>
  )
}


/** STYLES */
const AppContainer = styled.div<any>`
display: flex;
flex-direction: column;
flex-wrap: wrap;
width: 80%;
margin-left: ${({ ml }) => ml};
padding-top: 30px;
transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;
const Wrapper = styled.div`
margin-left: 1rem;
`;
const Title = styled.div`
h2 {
    text-transform: uppercase;
    padding-top: 1rem;
    color: #CD5C08;
    font-weight: 600;
    font-size: 20px;
}
`;
const ButtonWrapper = styled.div`
margin-top: 2rem;
`
const Button = styled.div`
width: max-content;
background-color: #CD5C08;
color: #fff;
font-weight: 400;
padding: .7rem;
border-radius: 5px;
font-size: 14px;
cursor: pointer;
`;

const DatatableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 1rem;
  margin-top: 3rem;
  ${Tables.datatable};
  ${tables.buttons};
`;

const TableContainer = styled.div`
  position: relative;
  display: flex;
  margin: 10px 0px 20px 0px;
  flex-direction: column;
  border: 1px solid #ccc;
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);

  border-radius: 10px;
  background-color: #fff;
  padding: 50px;

  &:hover {
    /* box-shadow: ${({ theme }) => theme.hover}; */
  }
`;
export default Meter