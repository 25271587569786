import { css, keyframes } from 'styled-components';
import { fadeIn, slideInRight } from 'react-animations';
import screen from './screen';

const decorate = {
  
  animate: {
    fadeIn: (duration: number) => css`animation: ${duration}s ${keyframes(fadeIn)};`,
    slideInRight: (duration: number) => css`animation: ${duration}s ${keyframes(slideInRight)};`
  },

  select: css`
    margin: 10px;
    display: flex;
    outline: none;
    flex-direction: row;
    .select-css {
      outline: none;
      display: block;
      font-size: 13px;
      font-family: sans-serif;
      font-weight: 700;
      color: #17274F;
      padding: .6em 1.4em .5em 1em;
      width: max-content;
      box-sizing: border-box;
      margin: 0;
      border: 1px solid #17274F;
      border-radius: .5em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg fill='000' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat, repeat;
      background-position: 100% 50%;
      background-size: o.5em;
      background-color: #E1EEFF;
      background-color: #fff;
      transition: all 0.8s ease;
      cursor: pointer;

      // margin-right: 23px;
    }
    .select-css::-ms-expand {
      display: none;
    }
    .select-css:hover {
      background-color: #E1EEFF;
    }
    /* Focus style */
    .select-css:focus {
      background-color: #E1EEFF;
      outline: none;
    }
    .select-css option {
      font-weight: normal;
      background-color: #fff;
      color: #000;
    }
    *[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
      background-position: left .7em top 50%, 0 0;
      padding: .6em .8em .5em 1.4em;
    }

    .select-css:disabled, .select-css[aria-disabled=true] {
      color: graytext;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    }
    
    .select-css:disabled:hover, .select-css[aria-disabled=true] {
      border-color: #aaa;
    }

    ${screen.largeScreen`
      flex-direction: column;
      align-items: center;
    `};
  `,
};

export default decorate;
